<template>
  <div>
    <DetailItemEditCard 
    item-name="team" 
    :promises.sync="detailItemEditCardPromises" 
    title="Ploegleider toevoegen"
    :detailViewRouteLocation="{ name: RouteNames.TEAM_DETAIL, params: { teamId: teamId } }"
    confirmationMessage="Ploegleider succesvol toegevoegd" 
    update-store-action="teamsModule/putNewLeadersInTeam"
      :update-store-action-payload="{ teamId: teamId, newLeaders: getFormResultSet }"
      :success-callback="usersAddedSuccessCallback">
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12" md="6">
            <AutoCompleteWithSelectAll 
            v-model="selectedLeaders" 
            :items="leaders" 
            required
            :rules="[...getRequiredRules('Ploegleider moet ingevuld worden'),...getNotEmptyArrayRules('Ploegleider moet ingevuld worden')]" 
            label="Ploegleider"
            item-text="fullName"
            item-value="userId"
            noTranslate/>
          </v-col>
        </v-row>
      </template>
    </DetailItemEditCard>
  </div>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import RouteNames from "@/router/RouteNames";
import { getRequiredRules, getNotEmptyArrayRules } from '@/shared/utils/inputRulesUtils.js';
import AutoCompleteWithSelectAll from "../../../components/shared/fields/AutoCompleteWithSelectAll.vue";

export default {
  name: "AddTeamLeader",
  components: {
    DetailItemEditCard,
    AutoCompleteWithSelectAll
  },
  props: {
    teamId: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      RouteNames,
      selectedLeaders: [],
      leaders: [],
      autoCompletePromises: [this.fetchNoTeamUsers()],
      detailItemEditCardPromises: []
    }
  },
  methods: {
    getRequiredRules,
    getNotEmptyArrayRules,
    usersAddedSuccessCallback(data) {
      this.$router.push({ name: RouteNames.TEAM_DETAIL, params: { teamId: data.id } })
    },
    fetchNoTeamUsers() {
      return this.$store.dispatch("teamsModule/fetchNoTeamUsers", this.teamId).then(result => this.leaders = result)
    }
  },
  computed: {
    getFormResultSet() {
      return { leaderIds: this.selectedLeaders }
    }
  },
}
</script>